import React from 'react';
import { Bottom } from './footer/Bottom';
import { Menu } from './Menu';

export function Contact() {

    return (
        <>
            <Menu/>
            <Bottom />
        </>
    );
}