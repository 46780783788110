// import React from "react";
// import { Menu } from "./Menu";
// import { Bottom } from "./footer/Bottom";
// import wh from '../Components/progect1/wh.png';
// import loc from '../Components/progect1/MIGHTY STEEL WEBSITE USE/20201111_212717.jpeg'
// import ql from '../Components/progect1/quality.png';
// import capture from "../Components/progect1/mslsteel.jpg"
// import MIGHTYSTEELLINK2 from "../Components/progect1/MIGHTY STEEL LINK 2.jpg"
// import "../Style.css";

// export function About() {
//     return (
//         <>
//            <Menu/>
//             <div className="thome">
//                 <div className='step1 pt-2 pb-2'>
//                     <div className=" topcard mb-3">
//                         <div className="row g-0">
//                             <div className="col-md-4 p-3 ">
//                                 <img src={loc} style={{height:"280px"}} className="img-fluid rounded-start img1" alt="..." />
//                             </div>
//                             <div class="top col-md-8">
//                                 <div className='wh p-2'>
//                                     <img src={wh} className="img-fluid rounded-start img1" alt="..." />
//                                 </div>
//                                 <div className=" card-body p-1 aboutsty">
//                                 <h2 className="card-title">About us</h2>
//                                     <p className="card-text pt-2"><span className='toph'>Mighty Steel Links</span> was established in 2010 with the sole purpose of providing best class minerals and supreme quality service. Ever since, the company has managed to mark its presence in the industry by ensuring a cost-effective, safe, reliable and ethical service.</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='step1 pt-2 pb-2'>
//                     <div className=" topcard mb-3">
//                         <div className="row g-0">
//                             <div class="top col-md-4" style={{marginTop:"-40px"}}>
//                                 <div className="card-body p-5">
//                                 <h2 className="card-title">QUALITY POLICY</h2>
//                                     <p className="card-text pt-2"><span className='toph'>" Quality is our motto, and customer satishfation is our benifit ".</span> We have been trying our best <br />to walk on the path of providing superior quality at most competitive prices. We supply the materials<br /> as per the requirements and specifications as given by our customers.</p>
//                                 </div>
//                                 {/* <div className="col-md-12 p-3 " >
//                                 <img style={{height:"500px" , width:"1000px"}} src={MIGHTYSTEELLINK2} className="img-fluid rounded-start img1" alt="..." />
//                             </div> */}
//                             </div>
//                             <div className="col-md-4 p-3 ">
//                                 <img src={MIGHTYSTEELLINK2} className="img-fluid rounded-start img1" alt="..." />
//                             </div>
//                              {/* <div className="card-body p-8 top col-md-4 qualityfull">
//                                 <h2 className="card-title qualityhead">QUALITY POLICY</h2>
                                    
//                                     <p className="card-text pt-2 qualitycontent "><span className='toph'>" Quality is our motto, and customer satishfation is our benifit ".</span> We have been trying our best <br />to walk on the path of providing superior quality at most competitive prices. We supply the materials<br /> as per the requirements and specifications as given by our customers.</p>
//                                    <img style={{height:"350px" , width:"800px"}} src={capture} className="img-fluid rounded-start img1" alt="..." />
                                   
//                                 </div> */}
                             
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <Bottom />
//         </>
//     );
// }
import React from "react";
import { Menu } from "./Menu";
import { Bottom } from "./footer/Bottom";
import wh from '../Components/progect1/wh.png';
import loc from '../Components/progect1/MIGHTY STEEL WEBSITE USE/20201111_212717.jpeg';
import capture from "../Components/progect1/mslsteel.jpg";
import MIGHTYSTEELLINK2 from "../Components/progect1/MIGHTY STEEL LINK 2.jpg";
import "../Components/Style1.css";

export function About() {
    return (
        <>
            <Menu />
            <div className="container thome1">
                <div className="row step1 pt-2 pb-2">
                    <div className="col-12 topcard mb-3">
                        <div className="row g-0">
                            <div className="col-md-4 p-3">
                                <img src={loc} style={{ height: "280px", width: "100%" }} className="img-fluid rounded-start img1" alt="Location" />
                            </div>
                            <div className="col-md-8 d-flex flex-column">
                                <div className="wh p-2">
                                    <img src={wh} className="img-fluid rounded-start img1" alt="WH" />
                                </div>
                                <div className="card-body p-1 aboutsty">
                                    <h2 className="card-title">About Us</h2>
                                    <p className="card-text pt-2">
                                        <span className="toph">Mighty Steel Links</span> was established in 2010 with the sole purpose of providing best class minerals and supreme quality service. Ever since, the company has managed to mark its presence in the industry by ensuring a cost-effective, safe, reliable, and ethical service.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row step1 pt-2 pb-2">
                    <div className="col-12 topcard mb-3">
                        <div className="row g-0">
                            <div className="col-md-4 p-3">
                                <img src={MIGHTYSTEELLINK2} style={{ height: "420px", width: "100%" }} className="img-fluid rounded-start img1" alt="Mighty Steel Link 2" />
                            </div>
                            <div className="col-md-8 d-flex flex-column">
                                <div className="card-body p-1 aboutsty">
                                    <h2 className="card-title" style={{fontSize:"27px"}}>QUALITY POLICY</h2>
                                    <p className="card-text pt-2">
                                        <span className="toph">"Quality is our motto, and customer satisfaction is our benefit."</span> We have been trying our best to walk on the path of providing superior quality at the most competitive prices. We supply the materials as per the requirements and specifications as given by our customers.
                                    </p>
                                </div>
                                <img src={capture} style={{ height: "280px", width: "100%" }} className="img-fluid rounded-start img1 mt-2" alt="Capture" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </>
    );
}
