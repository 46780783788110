// import React from 'react';
// import Zircon from '../Components/progect1/Zircon Sand & Flour.jpg';
// import Chromite from '../Components/progect1/Chromite Sand1.jpg';
// import Siliminate from '../Components/progect1/Siliminate.jpeg';
// import Investment from '../Components/progect1/Investment Castings Shell Products.jpg';
// import Ceramic from '../Components/progect1/Ceramic Sand.jpg';
// import Methodings from '../Components/progect1/Methodings of Steel & Iron Castings.jpg';
// import tl from '../Components/imgs/tiltlelogo.jpg';
// import t1 from '../Components/imgs/titlecard.jpg';
// // import t2 from '../Components/imgs/titlecard2.png';
// // import ser1 from '../Components/progect1/ser1.png';
// // import ser2 from '../Components/progect1/ser2.png';
// // import ser3 from '../Components/progect1/ser3.png';
// // import ser5 from '../Components/progect1/ser5.png';
// import flour from "../Components/progect1/flour.jpg"
// import Rutile from "../Components/progect1/Rutile.jpg";
// import ARCCUTROD from "../Components/progect1/Arc Cutting Rod's.jpg"
// import Weldingelectrodes from "../Components/progect1/Welding Electrodes.jpg"
// import FoundryCoating from "../Components/progect1/Foundry Coating.jpg"
// import CastIronIndustries from "../Components/progect1/Cast Iron Industries.avif"
// import SteelIndustries from "../Components/progect1/steelfoundry.webp"
// import Refractory from "../Components/progect1/refractoryindustries.jpg"
// import { Bottom } from './footer/Bottom';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import { Menu } from './Menu';
// import "../Style.css"


// export function Home() {
//     var settings = {
//         infinite: true,
//         speed: 2000,
//         slidesToShow: 6,
//         slidesToScroll: 1,
//         swipeToSlide: true,
//         initialSlide: 0,
//         autoplay: true,
//         autoplaySpeed: 2000,
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 3,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     initialSlide: 2
//                 }
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1
//                 }
//             }
//         ]
//     };


//     return (
//         <>
//             <Menu />
//             <div className='websitefont container-fluid'>


//                 <div className='step-1'>
//                     <div className="slider-container">
//                         <Slider {...settings}>
//                             <div className='scrolling'>
//                                 <a href=" "><img src={Zircon} className="card-img-top srcimg" alt="..." /></a>
//                                 <a href=" "><p className='d-flex justify-content-center'>Zircon</p></a>
//                             </div>
//                             <div className='scrolling'>
//                                 <a href=" "><img src={Chromite} className="card-img-top srcimg" alt="..." /></a>
//                                 <a href=" "><a href=" "><p className='d-flex justify-content-center'>Chromite</p></a></a>
//                             </div>
//                             <div className='scrolling'>
//                                 <a href=" "><img src={Siliminate} className="card-img-top srcimg" alt="..." /></a>
//                                 <a href=" "><p className='d-flex justify-content-center'>Siliminate</p></a>
//                             </div>
//                             <div className='scrolling'>
//                                 <a href=" "><img src={Investment} className="card-img-top srcimg" alt="..." /></a>
//                                 <a href=" "><p className='d-flex justify-content-center'>Investment Castings</p></a>
//                             </div>
//                             <div className='scrolling'>
//                                 <a href=" "><img src={Ceramic} className="card-img-top srcimg" alt="..." /></a>
//                                 <a href=" "><p className='d-flex justify-content-center'>Ceramic Sand</p></a>
//                             </div>
//                             <div className='scrolling'>
//                                 <a href=" "><img src={ARCCUTROD} className="card-img-top srcimg" alt="..." /></a>
//                                 <a href=" "><p className='d-flex justify-content-center'>Arc Cutting Rod's</p></a>
//                             </div>
//                         </Slider>
//                     </div>
//                 </div>

//                 <div className='step-2'>
//                     <div>
//                         <div className="row g-0">
//                             <div  className="col-md-4 p-2">
//                                 <img src={tl} style={{border:"2px solid red", height:"200px" , width:"500px"}} className="img-fluid rounded-start " alt="..." />
//                             </div>
//                             <div class="top col-md-8 p-2">
//                                 <div className=' p-2'>
//                                     <img src={t1} className="img-fluid rounded-start " alt="..." />
//                                 </div>
//                                 {/* <div className=' p-2'>
//                                     <img src={t2} className="img-fluid rounded-start " alt="..." />
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className=' step-3 '>

//                     <h4 className='Subtitle1 '>PRODUCTS</h4>
//                     <b><h3 className='Subtitle2 '><u>Our Popular Products</u></h3></b>

//                     <div class="row row-cols-1 row-cols-md-4 g-4 pb-2 sizeadjust ">
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Zircon} className="card-img-top" alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Zircon Sand</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Chromite} className="card-img-top " alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Chromite Sand</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Siliminate} class="card-img-top " alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Siliminate Sand & Flour</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Investment} className="card-img-top " alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Investment Castings Shell Products</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Ceramic} className="card-img-top " alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Ceramic Sand</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Methodings} className="card-img-top " alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Melting & Ladle Additives</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={flour} class="card-img-top" alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Zircon Flour</h5>
//                                 </div>
//                             </div>
//                         </div>
//                         <div class="col">
//                             <div class="card h-100 homecard">
//                                 <img src={Rutile} style={{backgroundColor:"white"}} class="card-img-top" alt="..." />
//                                 <div class="card-body">
//                                     <h5 className="card-title">Rutile</h5>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>

//                 </div>

//                 <div className=' step-4 '>

//                     <h4 className='Subtitle1 '>INDUSTRIES WE SERVED</h4>
//                     <b><h3 className='Subtitle21 '><u>Hish-Quality Tailored Services </u><br /></h3></b><span><p className='Subtitle2'>(We serve the ferroous and non-ferrous foundries)</p></span>

//                     <div className='pb-2 imgsize' >

//                         <div class="row row-cols-1 row-cols-md-5 g-4">
//                             <div class="col">
//                                 <div class="card h-100">
//                                 <img src={CastIronIndustries} className="card-img-top" alt="..." />
//                                         <div class="card-body">
//                                         <h5 className="card-title">Cast Iron & SG Iron Industries</h5>
//                                         </div>
//                                 </div>
//                             </div>
//                             {/* <div class="col">
//                                 <div class="card h-100">
//                                 <img src={ser2} className="card-img-top " alt="..." />
//                                         <div class="card-body">
//                                         <h5 className="card-title">SG Iron Industries</h5>
//                                         </div>
//                                 </div>
//                             </div> */}
//                             <div class="col">
//                                 <div class="card h-100">
//                                 <img src={SteelIndustries} className="card-img-top " alt="..." />
//                                         <div class="card-body">
//                                         <h5 className="card-title">Steel Industries</h5>
//                                         </div>
//                                 </div>
//                             </div>
//                             <div class="col">
//                                 <div class="card h-100">
//                                 <img src={Weldingelectrodes} className="card-img-top " alt="..." />
//                                         <div class="card-body">
//                                         <h5 className="card-title">Welding Electrodes</h5>
//                                         </div>
//                                 </div>
//                             </div>
//                             <div class="col">
//                                 <div class="card h-100">
//                                 <img src={Refractory} className="card-img-top " alt="..." />
//                                         <div class="card-body">
//                                         <h5 className="card-title">Refractory Industries</h5>
//                                         </div>
//                                 </div>
//                             </div>
//                             <div class="col">
//                                 <div class="card h-100">
//                                 <img src={FoundryCoating} className="card-img-top " alt="..." />
//                                         <div class="card-body">
//                                         <h5 className="card-title">Foundry Coatings</h5>
//                                         </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//             </div>
//             <Bottom />
//         </>
//     );
// }
import React from 'react';
import Zircon from '../Components/progect1/Zircon Sand & Flour.jpg';
import Chromite from '../Components/progect1/Chromite Sand1.jpg';
import Siliminate from '../Components/progect1/Siliminate.jpeg';
import Investment from '../Components/progect1/Investment Castings Shell Products.jpg';
import Ceramic from '../Components/progect1/Ceramic Sand.jpg';
import Methodings from '../Components/progect1/Methodings of Steel & Iron Castings.jpg';
import tl from '../Components/imgs/tiltlelogo.jpg';
import t1 from '../Components/imgs/titlecard.jpg';
import flour from "../Components/progect1/ZIRCON FLOUR 300 MESH .jpg"
import Rutile from "../Components/progect1/Rutile.jpg";
import ARCCUTROD from "../Components/progect1/Arc Cutting Rod's.jpg"
import Weldingelectrodes from "../Components/progect1/Welding Electrodes.jpg"
import FoundryCoating from "../Components/progect1/Foundry Coating.jpg"
import CastIronIndustries from "../Components/progect1/Cast Iron Industries.avif"
import SteelIndustries from "../Components/progect1/steelfoundry.webp"
import Refractory from "../Components/progect1/refractoryindustries.jpg"
import paintindustries from "../Components/progect1/paintindustry.webp"
import { Bottom } from './footer/Bottom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Menu } from './Menu';
import "../Style.css"
import "../home.css"; // Import the new CSS file here

export function Home() {
    var settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        swipeToSlide: true,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Menu />
            <div className='websitefont container-fluid'>
                <div className='step-1'>
                    <div className="slider-container">
                        <Slider {...settings}>
                            <div className='scrolling'>
                                <a href=" "><img src={Zircon} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Zircon</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={flour} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Zircon Flour</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={Chromite} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><a href=" "><p className='d-flex justify-content-center'>Chromite</p></a></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={Siliminate} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Siliminate</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={Investment} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Investment Castings</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={Ceramic} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Ceramic Sand</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={Rutile} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Rutile</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={ARCCUTROD} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Arc Cutting Rod's</p></a>
                            </div>
                            <div className='scrolling'>
                                <a href=" "><img src={Methodings} className="card-img-top srcimg" alt="..." /></a>
                                <a href=" "><p className='d-flex justify-content-center'>Metal Additive Product</p></a>
                            </div>
                        </Slider>
                    </div>
                </div>

                <div className='step-2'>
                    <div>
                        <div className="row g-0">
                            <div  className="col-md-4 p-2">
                                <img src={tl} style={{border:"2px solid red", height:"200px" , width:"500px"}} className="img-fluid rounded-start " alt="..." />
                            </div>
                            <div class="top col-md-8 p-2">
                                <div className=' p-2'>
                                    <img src={t1} className="img-fluid rounded-start " alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=' step-3 '>
                    <h4 className='Subtitle1 '>PRODUCTS</h4>
                    <b><h3 className='Subtitle2 ' style={{fontSize:"22px"}}><u>Our Popular Products</u></h3></b>

                    <div class="row row-cols-1 row-cols-md-4 g-4 pb-2 sizeadjust ">
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Zircon} className="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Zircon Sand</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={flour} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Zircon Flour</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Chromite} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Chromite Sand</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Siliminate} class="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Siliminate Sand & Flour</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Investment} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Investment Castings Shell Products</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Ceramic} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Ceramic Sand</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Rutile} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Rutile</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Weldingelectrodes} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Welding Electrodes</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='step-4'>
                    <div>
                        <h4 className='Subtitle1 '>CUSTOMIZED SOLUTIONS</h4>
                        <b><h3 className='Subtitle2 ' style={{fontSize:"22px"}}><u>Our Supply Industries</u></h3></b>
                    </div>

                    <div class="row row-cols-1 row-cols-md-5 g-4 pb-2 imgsize ">
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={CastIronIndustries} className="card-img-top" alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Cast Iron Industries</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={SteelIndustries} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Steel Industries</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Refractory} className="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Refractory Industries</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={Weldingelectrodes} class="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Welding Electrodes Industries</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card h-100 homecard">
                                <img src={paintindustries} class="card-img-top " alt="..." />
                                <div class="card-body">
                                    <h5 className="card-title">Paint Industries</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom />
        </>
    );
}

