// import React from 'react';
// import navelogo from '../Components/progect1/logo1.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone,faEnvelope,faCircleCheck } from '@fortawesome/free-solid-svg-icons';
// import { faFacebookF,faTwitter } from '@fortawesome/free-brands-svg-icons';
// import phone from "../Components/progect1/phone.jpg"
// import email from "../Components/progect1/email.jpg"
// import website from "../Components/progect1/website.png"
// export function Menu() {

//     const phoneNumber = "850-898-6866";

//     const handleClick = () => {
//       window.open(`tel:${phoneNumber}`);
//     };


//     return (
//         <>

//             <div className='websitefont'>

//                 <div className="Topnavebar">

//                     <nav className="navbar">
//                         <div className="container-fluid">
//                             <div className='d-flex gap-2'>
//                                 <div className='d-flex align-items-center'><a href='# '><img src={navelogo} alt="" className='navelogo' /></a></div>
//                                 <div className='d-flex ps-2'>
//                                     <div className='col'>
//                                         <div><a href="# " className='navehead'><b><h4>MIGHTY STEEL LINKS</h4></b></a></div>
//                                         <div><a href="# " className='navehead1'><p>S.F.No.399/1A,2B, Vijayakumar layout Road, Behind GCT Nagar-2,<br/> Kasthurinaickenpalayam, Vadavalli(Po), Coimbatore - 641041 . <span className='navehead'><br/><FontAwesomeIcon icon={faCircleCheck} style={{color: "#ff0000",}} />  GST  <span className='gst'> 33BRMPK8959G1Z8</span></span></p></a></div>
//                                     </div>
//                                     <div style={{marginLeft:"45px",border:"1px solid White",display:"flex",fontSize:"1px"}}>
//                                     <div style={{ border:"1px solid white",margin:"6px",marginleft:"2px",height:"100px", width:"200px"}}>
//                                         <div style={{display:"flex"}}>
//                                         <div> <img style={{hight:"50px",width:"50px"}} src={phone} /></div>
//                                         <div>
//                                      <h4 style={{fontSize:"20px",marginTop:"10px",paddingLeft:"2px"}}> Phone</h4>
//                                      </div>
//                                     </div>
//                                     <div style={{paddingLeft:"20px"}}>
//                                         <h6>+91 85089 86866</h6>
//                                     </div>
//                                     </div>
//                                     <div>
//                                     <div style={{height:"100px",margin:"6px", width:"240px",border:"1px solid white"}}>
//                                         <div style={{display:"flex"}}>
//                                         <div> <img style={{hight:"50px",width:"50px"}} src={email} /></div>
//                                         <div>
//                                      <h4 style={{fontSize:"20px",marginTop:"10px",paddingLeft:"2px"}}>Email Address</h4>
//                                      </div>
//                                     </div>
//                                     <div style={{paddingLeft:"20px"}}>
//                                         <h6>mightysteellinks@gmail.com</h6>
//                                     </div>
//                                     </div>
//                                     </div>
                                    
//                                     <div style={{height:"100px", margin:"6px",width:"250px",border:"1px solid white"}}>
//                                         <div style={{display:"flex"}}>
//                                         <div> <img style={{hight:"50px",width:"50px"}} src={website} /></div>
//                                         <div>
//                                      <h4 style={{fontSize:"20px",marginTop:"10px",paddingLeft:"2px"}}>Website</h4>
//                                      </div>
//                                     </div>
//                                     <div style={{paddingLeft:"20px"}}>
//                                         <h6>www.mightysteellinks.in</h6>
//                                     </div>
//                                     </div>
//                                     </div>
//                                 </div>
//                             </div>
                            
//                             {/* <div className='d-flex align-items-center'> <a href='# ' className='naveicon1' onClick={handleClick} style={{ cursor: "pointer" }}><FontAwesomeIcon icon= {faPhone} className='naveicon'/></a> <a href='mailto:mightysteellinks@gmail.com' className='naveicon1'><FontAwesomeIcon icon={faEnvelope} className='naveicon' /></a><a href='# ' className='naveicon1'> <FontAwesomeIcon icon={faTwitter} className='naveicon' /></a> <a href='# ' className='naveicon1'><FontAwesomeIcon icon={ faFacebookF} className='naveicon'/></a>
//                             <button className="navbar-toggler navebtn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
//                                 <span className="navbar-toggler-icon"></span>
//                             </button>
//                             </div> */}
//                             <div className="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
//                                 <div className="offcanvas-header">
//                                     <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">MIGHTY STEEL LINKS</h5>
//                                     <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
//                                 </div>
//                                 <div className="offcanvas-body">
//                                     <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
//                                         <li className="nav-item">
//                                             <a className="nav-link active" aria-current="page" href="/">Home</a>
//                                         </li>
//                                         <li className="nav-item">
//                                             <a className="nav-link active" href="/about">About</a>
//                                         </li>
//                                         <li className="nav-item dropdown">
//                                             <a className="nav-link dropdown-toggle" href="# " role="button" data-bs-toggle="dropdown" aria-expanded="false">
//                                                 More
//                                             </a>
//                                             <ul className="dropdown-menu dropdown-menu-dark">
//                                                 <li><a className="dropdown-item" href="/product">Product</a></li>
//                                                 <li><a className="dropdown-item" href="/case">Enquiry</a></li>
//                                             </ul>
//                                         </li>
//                                         <li className="nav-item">
//                                             <a className="nav-link active" href="/contact ">Contact</a>
//                                         </li>
//                                     </ul>
//                                     <form className="d-flex mt-3" role="search">
//                                         <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
//                                         <button className="btn btn-primary" type="submit">Search</button>
//                                     </form>
//                                 </div>
//                             </div>
//                         </div>
//                     </nav>

//                     <div className="navbaritoms">
//                         <a href="/ "><h6>Home</h6></a>
//                         <a href="/about "><h6>About</h6></a>
//                         <a href="/product "><h6>Products</h6></a>
//                         <a href="/case "><h6>Enquiry</h6></a>
//                         <a href="/contact "><h6>Contact</h6></a>
//                         <div className='navbaritoms2 pe-5'><a href="/case"><h6>Get Quote</h6></a></div>
//                     </div>

//                 </div>


//             </div>

//         </>
//     );
// }
import React from 'react';
import navelogo from '../Components/progect1/logo1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import phone from "../Components/progect1/phone.jpg";
import email from "../Components/progect1/email.jpg";
import website from "../Components/progect1/website.png";
import headd from "../Components/progect1/MIGHTY STEEL WEBSITE USE/headd.png"
import './Menu.css'; // Assuming you have a separate CSS file for custom styles

export function Menu() {

    const phoneNumber = "850-898-6866";

    const handleClick = () => {
      window.open(`tel:${phoneNumber}`);
    };

    return (
        <div className='websitefont'>
            <div className="Topnavebar">
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex gap-2'>
                                <div className='d-flex align-items-center'>
                                    <a href='# '><img src={navelogo} alt="Logo" className='navelogo' /></a>
                                </div>
                                <div className='d-flex ps-2'>
                                    <div className='col'>
                                    <div>
                                            <a href="# " className='navhead' style={{fontWeight: "900",
                                                    fontFamily:"fantasy" ,
                                                    color: "#03039e",
                                                    textDecoration: "none"}}>
                                             <h4><img src={headd}/></h4>
                                            </a>
                                            </div>

                                        <div>
                                            <a href="# " className='navehead1'>
                                                <p>
                                                    S.F.No.399/1A,2B, Vijayakumar layout Road, Behind GCT Nagar-2,<br />
                                                    Kasthurinaickenpalayam, Vadavalli(Po), Coimbatore - 641041 . 
                                                    <span className='navehead'>
                                                        <br />
                                                        <FontAwesomeIcon icon={faCircleCheck} style={{color: "#ff0000"}} />  
                                                        <span style={{color:"blue"}}>GST</span>  <span className='gst' style={{color:"#32cd32",fontWeight:"bold"}}> 33BRMPK8959G1Z8</span>
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='contact-info'>
                                <div className='contact-item'>
                                    <img src={phone} alt="Phone" className='contact-icon' />
                                    <div className='contact-details'>
                                        <h4 style={{fontSize:"18px"}}>Phone</h4>
                                        <h6 style={{fontSize:"14px",fontWeight:"500"}}>8508986866</h6>
                                    </div>
                                </div>
                                <div className='contact-item'>
                                    <img src={email} alt="Email" className='contact-icon' />
                                    <div className='contact-details'>
                                        <h4 style={{fontSize:"18px"}}>Email</h4>
                                        <a style={{textDecoration:"undedline",color:"black"}} href="mailto:mightysteellinks@gmail.com"><h6>mightysteellinks@gmail.com</h6></a>
                                    </div>
                                </div>
                                <div className='contact-item'>
                                    <img src={website} alt="Website" className='contact-icon' />
                                    <div className='contact-details'>
                                        <h4 style={{fontSize:"18px"}}>Website</h4>
                                        <h6>www.mightysteellinks.in</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="navbaritoms">
                       <a href="/ "><h6>Home</h6></a>
                       <a href="/about "><h6>About</h6></a>
                        <a href="/product "><h6>Products</h6></a>
                        <a href="/case "><h6>Enquiry</h6></a>
                       <a href="/contact "><h6>Contact</h6></a>
                       <div className='navbaritoms2 pe-5'><a href="/case"><h6>Get Quote</h6></a></div>
                    </div>

            </div>
        </div>
    );
}

