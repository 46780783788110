// // SpecificationModal.js
// import React from 'react';
// import { Modal, Button, Table } from 'react-bootstrap';

// const SpecificationModal = ({ show, handleClose, title, specifications }) => {
//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Table striped bordered hover>
//           <thead>
//             <tr className="text-center">
//               <th>S No</th>
//               <th>ITEMS</th>
//               <th>SPECIFICATION</th>
//             </tr>
//           </thead>
//           <tbody>
//             {specifications.map((spec, index) => (
//               <tr key={index}>
//                 <td>{index + 1}</td>
//                 <td>{spec.item}</td>
//                 <td>{spec.spec}</td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default SpecificationModal;




import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import './SpecificationModal.css'; // Import the CSS file

const SpecificationModal = ({ show, handleClose, title, specifications }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="modal-title">{title}</Modal.Title> {/* Apply the style */}
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr className="text-center">
              <th>S No</th>
              <th>ITEMS</th>
              <th>SPECIFICATION</th>
            </tr>
          </thead>
          <tbody>
            {specifications.map((spec, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{spec.item}</td>
                <td>{spec.spec}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SpecificationModal;
