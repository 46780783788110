
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Home} from './Components/Home';
import  {Bottom } from './Components/footer/Bottom';
import { Case } from './Components/Case';
import { Product } from './Components/Product';
import { Menu } from './Components/Menu';
import { About } from './Components/about';
import { Contact } from './Components/contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/bottom" element={<Bottom />} />
        <Route path="/case" element={<Case/>} />
        <Route path='/product' element={<Product/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
    </Router>
  );
}

export default App;
