import React, { useState } from "react";
import { Menu } from "./Menu";
import { Bottom } from "./footer/Bottom";
import '../../src/case.css';

export function Case() {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        c_name: "",
        message: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Compose email body
        const emailBody = `Name: ${formData.name}%0D%0A
                           Phone: ${formData.phone}%0D%0A
                           Email: ${formData.email}%0D%0A
                           Company Name: ${formData.c_name}%0D%0A
                           Message: ${formData.message}`;

        // Create mailto link
        const mailtoLink = `mailto:mightysteellinks@gmail.com?subject=Enquiry&body=${emailBody}`;

        // Open mail client with pre-filled email
        window.location.href = mailtoLink;
    };

    return (
        <>
            <Menu />
            <div className="step6" style={{ backgroundColor: "#f0f0f0", padding: "20px", borderRadius: "5px" }}>
                <h3 className="product" style={{ color:"red" , fontSize: "30px", marginBottom: "10px", display:"flex", justifyContent:"center"}}>ENQUIRY</h3>
                <b>
                    <h1 className="products" style={{ fontSize: "32px", textDecoration: "underline",display:"flex", justifyContent:"center" }}>
                        Fill out the form we will reach you Shortly
                        <br />
                    </h1>
                </b>
                <section className="form_section p-5" style={{ background: "#fff", boxShadow: "0px 0px 10px rgba(0,0,0,0.1)", borderRadius: "5px" }}>
                    <div className="wrapper">
                        <div className="form_align">
                            <form id="contact_form" onSubmit={handleSubmit}>
                                <input type="text" placeholder="Name*" id="name" name="name" value={formData.name} onChange={handleChange} style={{ marginBottom: "10px", padding: "8px", width: "100%" }} required />
                                <input type="text" placeholder="Company Name*" id="c_name" name="c_name" value={formData.c_name} onChange={handleChange} style={{ marginBottom: "10px", padding: "8px", width: "100%" }} />
                                <input type="tel" placeholder="Phone Number*" id="phone" name="phone" value={formData.phone} onChange={handleChange} style={{ marginBottom: "10px", padding: "8px", width: "100%" }} required />
                                <input type="email" placeholder="Email Address*" id="email" name="email" value={formData.email} onChange={handleChange} style={{ marginBottom: "10px", padding: "8px", width: "100%" }} required />
                                <textarea name="message" id="message" placeholder="Message" value={formData.message} onChange={handleChange} style={{ marginBottom: "10px", padding: "8px", width: "100%", minHeight: "100px" }}></textarea>
                                <input type="hidden" id="h_capchaans" name="h_capchaans" value="11" />
                                <button type="submit" style={{ backgroundColor: "#007bff", color: "#fff", padding: "10px 20px", border: "none", cursor: "pointer", borderRadius: "5px" }}>Send Message</button>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Case;
